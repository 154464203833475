<template>
  <!-- Destruction -->
  <!-- Destruction en végétation et destruction en récolte sont commune -->
  <div class="tabs-material-subtitle">
    <Container>
      <h2>Destruction en {{ isVegetation() ? 'végétation' : 'récolte' }}</h2>
    </Container>
  </div>

  <Table
    :headers="headers"
    :static-headers="staticHeaders"
    :items="modalities"
    :hiddenItems="['id', 'ordre']"
  >
  </Table>

</template>

<script>

import Container from '@/components/layout/Container.vue'
import Table from '@/components/table/Table.vue'

export default {
  name: 'DestructionSubview',
  components: {
    Table,
    Container,
  },

  props: {
    pageTitle: {
      type: String,
    },
    task: {
      type: Object,
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      fiche: {},
      headers: [],
      staticHeaders: [],
      modalities: [],
    }
  },

  emits: ['set-editable'],

  watch: {
    '$route.name': {
      deep: true,
      handler() {
        if (typeof this.$route.params.tid !== 'undefined') {
          this.setComponent()
        }
      },
    },
  },

  mounted() {
    this.getFiche()
    this.setComponent()
  },

  methods: {
    async getFiche() {
      this.fiche = await this.taskService.getFiche(this.$route, 'destruction')
    },
    async setComponent() {
      const { modalities, headers } = await this.taskService.initializeComponent(this.$route)

      this.modalities = modalities
      this.headers = headers
    },

    /**
     * Check if destruction is vegetation
     * @returns {boolean}
     */
    isVegetation() {
      return this.$route.name.includes('Vegetation')
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
